import React, { useState, useContext, useEffect, useHistory } from "react";
import { Select, Checkbox, Radio, Space, Spin } from "antd";

import { Layout } from "antd";
const { Footer } = Layout;

import { Form, Input, Button } from "antd";

import { Row, Col } from "antd";

import axios from "axios";

import { CollationPortalContext } from "../context";
import styled from "styled-components";
import { Navbar } from "../Navbar";

import ReactPanZoom from "react-image-pan-zoom-rotate";

import "./style.css";

function HomePage(props) {
	const {} = props;

	const [formatedResult, setFormatedResult] = useState(null);
	const [formIntact, setFormIntact] = useState(false);
	const [formStamped, setformStamped] = useState(false);
	const [isPhone, setIsPhone] = useState(false);
	const [boxAPC, setBoxAPC] = useState(false);
	const [boxPDP, setboxPDP] = useState(false);
	const [boxLp, setboxLp] = useState(false);
	const [showRegForm, setShowRegForm] = useState(false);
	const [boxNNPP, setboxNNPP] = useState(false);

	const methods = useContext(CollationPortalContext);

	const {
		stateOptions,
		dataCode,
		submitTranscribe,
		setState,
		setLga,
		pdfToImage,
		formRef,
		invalidForm,
		transcribeFormIsLoading,
		ipAddress,
		lgaOptions,
		fetchLgas,
		fetchWards,
		newWardArray,
		fetchPollingUnits,
		newPollingUnitArray,
		resultDoc,
		imageLoading,
		unclearImage,
		stateValue,
		lgaValue,
		wardValue,
		pollingUnitValue,
		state,
		lga,
		handleTranscribe,
	} = methods;

	const onFinish = async (values) => {

		console.log(values);
		const data = {
			state: values.state,
			lga: values.state,
			ward: values.state,
			accreditedVoters: values.accreditedVoters,
			apcData: [values.apc, boxAPC],
			lpData: [values.lp, boxLp],
			nnppData: [values.nnpp, boxNNPP],
			pdpData: [values.pdp, boxPDP],
			stateCode: dataCode(
				values.state == undefined ? stateValue : values.state
			),
			wardCode: dataCode(values.ward == undefined ? wardValue : values.ward),
			lgaCode: dataCode(values.lga == undefined ? lgaValue : values.lga),
			pollingUnitCode: dataCode(
				values.polling_unit == undefined
					? pollingUnitValue
					: values.polling_unit
			),
			isStamped: formStamped,
			pollingUnit:
				values.polling_unit == undefined
					? pollingUnitValue
					: values.polling_unit,
			state: values.state == undefined ? stateValue : values.state,
			lga: values.lga == undefined ? lgaValue : values.lga,
			ward: values.ward == undefined ? wardValue : values.ward,
			isTampered: formIntact,
			resultUrl: resultDoc.url,
			resultId: resultDoc.id,
			userIp: ipAddress,
			isPhone: isPhone,
		};

		console.log(data);
 
		submitTranscribe(data);
		setBoxAPC(false);
		setboxLp(false);
		setboxNNPP(false);
		setboxPDP(false);
		setFormIntact(false);
		setformStamped(false);
	};

	const handleUnclearImage = async () => {
		const data = {
			resultUrl: resultDoc.url,
			resultId: resultDoc.id,
			userIp: ipAddress,
			stateCode: dataCode(stateValue),
			lgaCode: dataCode(lgaValue),
		};

		unclearImage(data);
	};

	const handleInvalidForm = async () => {
		const data = {
			resultUrl: resultDoc.url,
			resultId: resultDoc.id,
			userIp: ipAddress,
			stateCode: dataCode(stateValue),
			lgaCode: dataCode(lgaValue),
		};

		invalidForm(data);
	};

	const onFinishFailed = (errorInfo) => {};

	const onSearch = (errorInfo) => {};

	const onStateChange = (value) => {
		if (value != undefined) {
			let formattedValue = value.slice(5).replace(/\//g, "%2F");
			setState(value);
			fetchLgas(formattedValue);
		}
	};

	const onLgaChange = (value) => {
		if (value != undefined) {
			let formattedValue = value.slice(5).replace(/\//g, "%2F");
			setLga(value);
			fetchWards(formattedValue);
		}
	};

	const onWardSelect = (value) => {
		if (value != undefined) {
			let formattedValue = value.slice(5).replace(/\//g, "%2F");
			fetchPollingUnits(formattedValue);
		}
	};

	const onPollingUnitSelect = (data) => {
		// setPollingUnitValue(data)
	};

	function isImageUrl(str) {
		return /\.(jpeg|jpg|gif|png)$/i.test(str.trim());
	}

	useEffect(() => {
		if (resultDoc != null) {
			if (isImageUrl(resultDoc.url)) {
				setFormatedResult(resultDoc);
			} else {
			}
		}
	}, [resultDoc]);

	console.log(formatedResult);

	return (
		<div className="main-wrapper">
			<Navbar />

			<MainContainer>
				<Header>
					<h2>Instructions</h2>
					<p> ENTER THE NUMBERS YOU SEE IN THE IMAGE INTO THE TEXTBOXES</p>
				</Header>

				<div className="main-container-content">
					{imageLoading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
							}}
						>
							<div>
								<Spin style={{ fontSize: "50px", width: "200px" }} />
							</div>
						</div>
					) : (
						<Row gutter={0}>
							<Col lg={16} md={24} sm={24} xs={24}>
								<div
									style={{
										width: "100%",
									}}
								>
									<Form onFinish={handleTranscribe}>
										<Row gutter={10} justify="center" align="center">
											<Col lg={6}>
												<Form.Item>
													<Select
														showSearch
														placeholder="Select State"
														optionFilterProp=""
														onChange={onStateChange}
														onSearch={onSearch}
														allowClear={true}
														filterOption={(input, option) =>
															(option?.label ?? "")
																.toLowerCase()
																.includes(input.toLowerCase())
														}
														options={stateOptions}
													/>
												</Form.Item>
											</Col>

											<Col lg={6}>
												<Form.Item
													requiredMark={false}
													name="lga"
													rules={[
														{
															required: true,
															message: "Please select a lga",
														},
													]}
												>
													<Select
														showSearch
														placeholder="Select LGA"
														value={lgaValue}
														optionFilterProp=""
														onChange={onLgaChange}
														onSearch={onSearch}
														allowClear={true}
														filterOption={(input, option) =>
															(option?.label ?? "")
																.toLowerCase()
																.includes(input.toLowerCase())
														}
														options={lgaOptions}
													/>
												</Form.Item>
											</Col>
											<Col lg={6}>
												<Button
													className="btn"
													type="default"
													htmlType="submit"
												>
													{transcribeFormIsLoading ? (
														<Spin className="custom-spin" />
													) : (
														"APPLY"
													)}
												</Button>
											</Col>
										</Row>
									</Form>

									<div style={{ position: "relative" }}>
										<div
											className="image-container"
											style={{
												position: "relative",
												width: "95%",
												marginTop: "30px",
												height: "100%",
												overflowX: "hidden",
											}}
										>
											{imageLoading ? (
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<Spin className="custom-spin" />{" "}
												</div>
											) : formatedResult != null ? (
												<ReactPanZoom
													alt="cool image"
													image={`${formatedResult.url}`}
												/>
											) : (
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														alignContent: "center",
														marginTop: "100px",
													}}
												>
													<p style={{ fontSize: "1.2em" }}>
														No image found please try again
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
							</Col>

							<Col lg={8} md={24} sm={24} xs={24}>
								<Row>
									<Col lg={2}>
										<Divider />
									</Col>
									{imageLoading ? (
										<Spin className="custom-spin" />
									) : (
										<Col lg={22} md={8}>
											<h3>
												Registration Area{" "}
												<Button
													onClick={() => setShowRegForm(!showRegForm)}
													type="text"
													danger
												>
													EDIT AREA
												</Button>{" "}
											</h3>

											<Form
												ref={formRef}
												name="my-form"
												initialValues={{ remember: true }}
												onFinish={onFinish}
												onFinishFailed={onFinishFailed}
											>
												{showRegForm ? (
													<div className="registratio-area">
														<Form.Item
															requiredMark={false}
															name="state"
															rules={[
																{
																	required: true,
																	message: "Please select a state",
																},
															]}
														>
															<Select
																showSearch
																placeholder="Select State"
																optionFilterProp=""
																onChange={onStateChange}
																onSearch={onSearch}
																allowClear={true}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																options={stateOptions}
															/>
														</Form.Item>

														<Form.Item
															requiredMark={false}
															name="lga"
															rules={[
																{
																	required: true,
																	message: "Please select a lga",
																},
															]}
														>
															<Select
																showSearch
																placeholder="Select LGA"
																value={lgaValue}
																optionFilterProp=""
																onChange={onLgaChange}
																onSearch={onSearch}
																allowClear={true}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																options={lgaOptions}
															/>
														</Form.Item>

														<Form.Item
															requiredMark={false}
															name="ward"
															rules={[
																{
																	required: false,
																	message: "Please select a ward",
																},
															]}
														>
															<Select
																showSearch
																placeholder="Select Ward"
																optionFilterProp=""
																allowClear={true}
																onChange={onWardSelect}
																onSearch={onSearch}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																options={newWardArray}
															/>
														</Form.Item>

														<Form.Item
															requiredMark={false}
															name="polling_unit"
															rules={[
																{
																	required: true,
																	message: "Please identify your poling unit",
																},
															]}
														>
															<Select
																showSearch
																placeholder="Identify Polling Unit"
																optionFilterProp=""
																onChange={onPollingUnitSelect}
																onSearch={onSearch}
																allowClear={true}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																options={newPollingUnitArray}
															/>
														</Form.Item>
													</div>
												) : (
													<div className="registratio-area">
														<div className="location-content">
															<p>{stateValue}</p>
														</div>
														<div className="location-content">
															<p>{lgaValue}</p>
														</div>
														<div className="location-content">
															<p>{wardValue}</p>
														</div>
														<div className="location-content">
															<p>{pollingUnitValue}</p>
														</div>
													</div>
												)}

												<div className="party-section">
													<div className="acc_voters">
														<Col lg={24} md={24} xs={24}>
															<Form.Item
																rules={[
																	{
																		required: true,
																		message: "Please enter a valid number",
																	},
																]}
																requiredMark={false}
																name="accreditedVoters"
															
															>
																<Input 	placeholder="Accredited Voters" type="text" />
															</Form.Item>
														</Col>
													</div>

													<InputContainer>
														<Row>
															<Col lg={6} md={6} xs={6}>
																<PartyLogo>
																	<img
																		src="https://forensic.nigeria2.com/app/logos/APC.png"
																		alt="Lgo"
																	/>
																	<span>APC</span>
																</PartyLogo>
															</Col>

															<Col lg={16} md={6} xs={16}>
																<Form.Item
																	rules={[
																		{
																			required: false,
																			message: "Please input a score",
																		},
																	]}
																	requiredMark={false}
																	name="apc"
																>
																	<Input type="number" />
																</Form.Item>
															</Col>
														</Row>

														<Checkbox
															checkedColor="#00cc00"
															checked={boxAPC}
															onChange={() => setBoxAPC(!boxAPC)} // handles the change event
														>
															APC representative did not sign this form
														</Checkbox>
													</InputContainer>
													<InputContainer>
														<Row>
															<Col lg={6} md={6} xs={6}>
																<PartyLogo>
																	<img
																		src="https://forensic.nigeria2.com/app/logos/LP.png"
																		alt="Lgo"
																	/>
																	<span>LP</span>
																</PartyLogo>
															</Col>

															<Col lg={16} md={6} xs={16}>
																<Form.Item
																	rules={[
																		{
																			required: true,
																			message: "Please input a score",
																		},
																	]}
																	requiredMark={false}
																	name="lp"
																>
																	<Input type="number" />
																</Form.Item>
															</Col>
														</Row>

														<Checkbox
															checked={boxLp}
															onChange={() => setboxLp(!boxLp)} //
														>
															LP representative did not sign this form.
														</Checkbox>
													</InputContainer>
													<InputContainer>
														<Row>
															<Col lg={6} md={6} xs={6}>
																<PartyLogo>
																	<img
																		src="https://forensic.nigeria2.com/app/logos/NNPP.png"
																		alt="Lgo"
																	/>
																	<span>NNPP</span>
																</PartyLogo>
															</Col>

															<Col lg={16} md={6} xs={16}>
																<Form.Item requiredMark={false} name="nnpp">
																	<Input
																		rules={[
																			{
																				required: true,
																				message: "Please input a score",
																			},
																		]}
																		type="number"
																	/>
																</Form.Item>
															</Col>
														</Row>

														<Checkbox
															checked={boxNNPP}
															onChange={() => setboxNNPP(!boxNNPP)} //// handles the change event
														>
															NNPP representative did not sign this form.
														</Checkbox>
													</InputContainer>
													<InputContainer>
														<Form.Item
															rules={[
																{
																	required: true,
																	message: "Please input a score",
																},
															]}
															requiredMark={false}
															name="pdp"
														>
															<Row>
																<Col lg={6} md={6} xs={6}>
																	<PartyLogo>
																		<img
																			src="https://forensic.nigeria2.com/app/logos/PDP.png"
																			alt="Lgo"
																		/>
																		<span>PDP</span>
																	</PartyLogo>
																</Col>

																<Col lg={16} md={6} xs={16}>
																	<Form.Item
																		rules={[
																			{
																				required: true,
																				message: "Please input a score",
																			},
																		]}
																		requiredMark={false}
																		name="pdp"
																	>
																		<Input type="number" />
																	</Form.Item>
																</Col>

																<Col lg={24} md={24} xs={24}>
																	<Checkbox
																		checked={boxPDP}
																		onChange={() => setboxPDP(!boxPDP)} //// handles the change event
																	>
																		PDP representative did not sign this form.
																	</Checkbox>
																</Col>
															</Row>
														</Form.Item>
													</InputContainer>
													<div className="checkboxes">
														<p>
															Do you think this form has been tampered with?
														</p>
														<div>
															<Form.Item>
																<Radio.Group
																	onChange={() => setFormIntact(!formIntact)}
																	value={formIntact}
																>
																	<Space direction="vertical">
																		<Radio value={true}>
																			Yes, there are corrections on this form
																		</Radio>
																		<Radio value={false}>
																			No, the form is intact
																		</Radio>
																	</Space>
																</Radio.Group>
															</Form.Item>
														</div>

														<div>
															<Form.Item>
																<Checkbox
																	checked={formStamped}
																	onChange={() => setformStamped(!formStamped)} //// handles the change event
																>
																	This form is not stamped
																</Checkbox>
															</Form.Item>
														</div>

														<div>
															<Form.Item>
																<Checkbox
																	checked={isPhone}
																	onChange={() => setIsPhone(!isPhone)} //// handles the change event
																>
																	Is this result uploaded with a mobile device
																</Checkbox>
															</Form.Item>
														</div>
													</div>

													<Form.Item>
														<Button
															className="submit-btn"
															type="primary"
															htmlType="submit"
														>
															{transcribeFormIsLoading ? (
																<Spin className="custom-spin" />
															) : (
																"Submit"
															)}
														</Button>
													</Form.Item>
												</div>
											</Form>

											<Row justify="space-between" gutter={20}>
												<Col lg={10} md={10} xs={10}>
													<Form.Item>
														<Button
															onClick={() => handleUnclearImage()}
															className="other-action-btn"
															type="primary"
															htmlType="submit"
														>
															Unclear Image
														</Button>
													</Form.Item>
												</Col>

												<Col lg={10} md={10} xs={10}>
													<Form.Item>
														<Form.Item>
															<Button
																onClick={() => handleInvalidForm()}
																className="other-action-btn"
																type="primary"
																htmlType="submit"
															>
																Invalid Form
															</Button>
														</Form.Item>
													</Form.Item>
												</Col>
											</Row>
										</Col>
									)}
								</Row>
							</Col>
						</Row>
					)}
				</div>
			</MainContainer>

			<Footer style={{ textAlign: "center" }}>
				Collation Portal | © 2023 All Rights Reserved
			</Footer>
		</div>
	);
}

export default HomePage;

const Header = styled.div`
	background: rgb(250, 250, 245);
	border-radius: 10px;
	text-align: center;
	padding: 28px;
	width: 100%;

	h2 {
		margin: 0px;
		padding: 0px;
		font-weight: 500;
		font-size: 1.5em;
		line-height: 24px;
		text-align: center;
		text-decoration-line: underline;
		color: rgb(17, 17, 17);
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
			Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	}

	p {
		max-width: 500px;
		margin-inline: auto;
		font-weight: 700;
		font-size: 16px;
		margin-top: 40px;
		line-height: 22px;
		line-height: 32px;

		text-align: center;
		color: rgb(182, 28, 28);
	}
`;

const InputContainer = styled.div`
	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: #008000; /* change the background color of the inner checkbox when checked */
		border-color: #008000; /* change the border color of the inner checkbox when checked */
	}

	.ant-form-item {
		margin-bottom: 2px !important;
	}

	.ant-row {
		/* height: 80px; */
	}
`;

const PartyLogo = styled.div`
	display: flex;
	-webkit-box-pack: start;
	justify-content: space-evenly;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	padding: 10px;
	/* background-color: rgb(20, 123, 92);
     */
	background-color: #008000;
	color: white;
	margin-right: 6px;

	img {
		width: 35px;
		height: 35px;
	}
`;

const Divider = styled.div`
	height: 100%;
	width: 50%;
	border-left: 1px solid rgb(229, 226, 237);
	margin: 0 10px;
`;

const MainContainer = styled.div`
	width: 80%;
	margin: 0 auto;

	@media (max-width: 768px) {
		width: 100%;
	}
	/* padding: 0px 20px; */
	/* min-height: calc(100vh - 300px); */
	@media (max-width: 768px) {
		padding: 0px 20px;
	}
	max-width: 1440px;
	.main-container-content {
		padding: 30px 0px;

		.ant-form-item {
			margin-bottom: 4px !important;
			p {
				font-size: 13px;
			}
		}

		.image-container {
			/* max-width: 100%;
            height: 90vh;; */
		}

		.party-section {
			margin-top: 40px;

			.acc_voters {
				.ant-input {
					margin-left: 0px !important;
					margin-bottom: 10px;
					width: 100% !important;
				}
			}
		}
		.image-container {
			@media (max-width: 768px) {
				position: static !important;
				margin-bottom: 20px;
			}
		}

		.checkboxes {
			p {
				margin: 10px 0px;
				font-weight: 500;
				font-size: 1rem;
			}
		}

		.pan-container {
			width: 100%;
		}

		.submit-btn {
			border-radius: 0px;
			cursor: pointer;
			padding: 12px 28px;
			color: rgb(255, 255, 255);
			border: 1px solid #008000;
			font-weight: bold;
			border-radius: 4px;
			background-color: #008000;
			border: 1px solid #008000;
			box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
			margin: 16px 0px 0px;
			height: 50px;

			:disabled {
				border: 1px solid rgb(221, 221, 221);
				cursor: not-allowed;
			}

			:hover {
				background-color: rgb(233, 39, 17);
				border: 1px solid rgba(255, 255, 255, 0.757);
			}
		}

		.bottom-text {
			/* width: 70%; */
			margin-top: 100px;
			text-align: center;
			font-weight: 500;
			font-size: 0.9em;
		}

		.other-action-btn {
			border-radius: 0px;
			cursor: pointer;
			padding: 12px 28px;
			color: rgb(255, 255, 255);
			border: 1px solid #34495e;
			font-weight: bold;
			background: #34495e;
			height: 50px;
			margin: 16px 0px 0px;
			:hover {
				background-color: rgb(233, 39, 17);
				border: 1px solid rgb(233, 39, 17);
			}
		}

		.btn {
			border-radius: 0px;
			cursor: pointer;
			padding: 12px 28px;
			color: rgb(255, 255, 255);
			border: 1px solid #34495e;
			font-weight: bold;
			background: #34495e;
			height: 50px;
			margin: 10px 0px 0px;
			:hover {
				background-color: rgb(233, 39, 17);
				border: 1px solid rgb(233, 39, 17);
			}
		}

		h3 {
			font-size: 1.3em;
			padding-bottom: 20px;
			font-weight: 600;
		}

		.ant-input {
			border: 1px solid #008000 !important;
			border-radius: 0px !important;
			width: 108% !important;
			height: 55px !important;
			height: auto;
			margin-left: 10px;
			padding: 10px !important;
		}

		.ant-select {
			margin-top: 10px;
		}

		.ant-select-focused {
			border: 2px solid #008000 !important;
			outline-color: #008000 !important ;
			border-radius: 4px;
			box-shadow: none;
			outline: none;
		}

		.ant-input:hover,
		.ant-input:focus {
			border-color: #d9d9d9; /* change border color on hover and focus */
			outline: none !important; /* remove outline on focus */
			box-shadow: none; /* remove box-shadow on focus */
		}

		.ant-select:hover {
			/* border: 0.5px solid rgb(20, 123, 92) !important;  */
			/* outline: 1px solid rgb(20, 123, 92) !important;  */
			border-radius: 4px;

			outline: none !important;
			box-shadow: none !important;
		}

		.ant-checkbox-wrapper {
			margin-top: 3px;
			margin-bottom: 5px;
		}

		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			/* border: 1px solid rgb(20, 123, 92) !important;  */
			border: 1px solid rgb(229, 226, 237);
			border-radius: 4px !important;
			width: 100% !important;
			height: auto;
			padding: 10px !important;

			.ant-select-selection-placeholder {
				font-size: 1rem;
				text-transform: capitalize;
				color: rgba(0, 0, 0, 0.6);
				font-family: Roboto, Helvetica, Arial, sans-serif;
				font-weight: 400;
				line-height: 1.4375em;
				letter-spacing: 0.00938em;
			}
		}

		.location-content {
			border: 1px solid #44424280 !important;
			border-radius: 0px !important;
			width: 100% !important;
			height: auto !important;

			padding-top: 10px !important;
			padding-left: 10px;
			margin-top: 10px;
			background-color: #eee;
		}
	}
`;
