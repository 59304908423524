import { Redirect, Route } from "react-router-dom";
import CollationProvider from "./context";

function ProtectedRoute({ component: Component, ...rest }) {

  let token = localStorage.getItem('token')

  return (
    <Route
      {...rest}
      render={(props) =>
        token != null && token != undefined && token != "undefined" ? (
           <CollationProvider>
                 <Component {...props} />
           </CollationProvider>
         
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}


export default ProtectedRoute;