
import styled from 'styled-components';
import React, { useState, useContext } from "react"
import { Link } from 'react-router-dom';

import { MailOutlined } from '@ant-design/icons';


import { AuthContext } from '../context/auth';

import { Form, Input, Button, Select, Spin } from 'antd';
import { Navbar } from '../Navbar';




export const SignIn = () => {

    const { loading, loginUser, ipAddress } = useContext(AuthContext)


    const handleSearch = (value) => {
        const filtered = options.filter((option) =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const onFinish = (values) => {
        loginUser(values)
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    return (

        <Container>


  
                <div class="left-pane">

                
                    <div className="form-container">
                    <Header>
                        <h2>Welcome back</h2>
                        <p>Welcome back! Please enter your details</p>
                    </Header>
                        <Form
                            layout='vertical'
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >


                            <Form.Item
                                label=""
                                name="destination"
                                requiredMark={false}


                                xs={{ span: 24 }} md={{ span: 12 }}
                                rules={[{ required: true, message: 'Please provide an email address!' }]}
                            >
                                <Input placeholder='Email Adress' />
                            </Form.Item>



                            <Form.Item>
                                <Button className='submit-btn' type="primary" htmlType="submit">
                                    <MailOutlined size={30} />  {loading ? <Spin /> : " Continue with Email"}
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className="link-cont">
                            <p>Already have an account? <span> <Link to="/">Sign Up</Link> </span></p>
                        </div>
                    </div>

                </div>
                <div class="right-pane">

               </div>


        </Container>
    );
}


const Container = styled.div`

    display: flex;
    flex-direction: row;
    height: 100vh;

    .left-pane {
        flex: 1; 
        /* background-color: #eee;  */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .right-pane {
        flex: 1; 
        background-image: url("../../img/banner.jpg");
        background-size: cover;

        @media (max-width: 786px) {

            display: none;
            
        }
    }

      .submit-btn{
        width: 100%;
        border-radius: 0px;
        cursor: pointer;
        padding: 12px 28px;
        color: rgb(255, 255, 255);
        border: 1px solid   #008000;;
        font-weight: bold;
        border-radius: 4px;
        background-color:  #008000;
        border: 1px solid #008000;
        margin: 16px 0px 0px;
        height: 50px;

            :disabled{
                border: 1px solid rgb(221, 221, 221);
                cursor: not-allowed;
            }
              
            :hover{
                background: transparent;
                background: rgba(255, 255, 255, 0.757);
                color: #008000;
            }
      }

    justify-content: center;


    .link-cont{
        display: flex;
        align-items: center;
        justify-content: space-evenly; 
        p{
            padding-top: 20px;
            color: #22222289;

            span a{

                color:  #008000;
            }
        }
        
    }

    .form-container{
        width: 500px;
        padding: 0px 30px;

        @media (max-width: 768px)  {
            width: 100%;
            padding: 0px 20px;
        }


    }
.ant-select{
    width: 100%;
    
}

 .ant-input, .ant-select-selector {
    width: 100% !important;
  border: 1px solid #ccc !important;
  padding: 12px 12px !important;
  font-size: 16px;
}


.ant-form{
    margin-top: 20px;
}

.ant-input:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.ant-input::placeholder {
  color: #999;
}
 
`;


const Header = styled.div`

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    h2{
        font-size: 30px;
        font-weight: 800;
        
    }
    p{
        font-size: 16px;
        color: #22222289;
        text-align: center;
    }

    @media (max-width: 786px) {
        h3{
            font-size: 20px;
        }
        p{
            font-size: 14px;
        }
    
}
 
`;