import { Menu } from 'antd';
import styled from 'styled-components';
import { Menu, Layout } from 'antd';
import { HomeOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';

const { Header } = Layout;

import { useHistory } from 'react-router-dom';
import { HomeOutlined, } from '@ant-design/icons';
import "./style.css"

export const Navbar = () => {

  const navigate = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("token")
    navigate.push("/login")
  }
  return (
    <MenuContainer>
      <Menu theme="light" mode="horizontal">
        <Menu.Item key="home" icon={<HomeOutlined />}>
          Collation Portal
        </Menu.Item>
        <div className="logout">
          <Menu.Item onClick={() => handleLogout()} key="Logout" icon={<UserOutlined />} style={{ float: 'right' }}>
            Logout
          </Menu.Item>

        </div>
      </Menu>
    </MenuContainer>
  );
}


const MenuContainer = styled.div`
height: 80px;

.navbar{
    display: flex;
    height: 100%;
    align-items: center;

    /* justify-content: center; */

     .ant-menu-item{
        color: #121212;
        text-decoration: none;
        font-size: 1.7em;
        font-weight: bold;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

        &:hover{
            text-decoration: none;
        }
    } 

    .logout{
      float: right !important;
    }
}
 
`;