import React, { createContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import pdfjsLib from "pdfjs-dist";

import { useParams, useHistory, useLocation } from "react-router-dom";
import { CustomNotification } from "../Notifications";
import Item from "antd/es/list/Item";

// Create a new context
export const CollationPortalContext = createContext();

const CollationProvider = ({ children }) => {
	const API_KEY = "loc_live42.wTu9250OyI2fl8kpF79Dqra9yzVqfR28+ffIkVHmNPw=";

	const [stateData, setStateData] = useState(null);
	const [lgaData, setLgaData] = useState(null);
	const [wardsData, setWardsData] = useState(null);
	const [pollingUnitData, setPollingUnitData] = useState(null);
	const [user, setUser] = useState({});
	const [imageLoading, setImageLoading] = useState(false);
	const [resultDoc, setResultDoc] = useState(null);
	const [regArea, setRegArea] = useState(null);
	const [stateValue, setStateValue] = useState("");
	const [lgaValue, setLgaValue] = useState("");
	const [wardValue, setWardValue] = useState("");
	const [pollingUnitValue, setPollingUnitValue] = useState("");
	const [ipAddress, setIpAddress] = useState("");
	const [transcribeFormIsLoading, settranscribeFormIsLoading] = useState(false);
	const [state, setState] = useState("");
	const [lga, setLga] = useState("");
	const formRef = useRef(null);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const BASE_URL = "https://coral-app-n4pvh.ondigitalocean.app/api/v1";

	const navigate = useHistory();

	let newWardArray = [];
	let newPollingUnitArray = [];

	const stateOptions = [];
	const lgaOptions = [];

	useEffect(() => {
		fetchData();
		fetchImage();
	}, []);

	const updateUser = (user) => {
		setUser(user);
	};

	const token = localStorage.getItem("token");

	const postOptions = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	async function fetchImage() {
		if (token != null && token != undefined && token != "undefined") {
			setImageLoading(true);
			await axios
				.get(
					`${BASE_URL}/result-doc/fetch?stateCode=10&lgaCode=14`,
					postOptions
				)
				.then((response) => {
					console.log("state response", response.data);
					setResultDoc(response.data);
					setImageLoading(false);
				})
				.catch((error) => {
					setImageLoading(false);
					console.log("Unsuccessfull", error);
				});
		}
	}

	async function verifyAccount() {
		const token = searchParams.get("token");
		const mode = searchParams.get("mode");

		if (token != null && token != undefined && token != "undefined") {
			if (mode == "v") {
				await axios
					.get(`${BASE_URL}/auth/verify-account?token=${token}`)
					.then((response) => {
						localStorage.setItem("token", response.data.token);
						console.log(response.data);
						CustomNotification({
							title: "",
							type: "success",
							message: response.data.message,
							duration: 3,
						});
						navigate.push("/dashboard");
					})
					.catch((error) => {
						console.log(error.response.data);
						if (error.data) {
							CustomNotification({
								title: "",
								type: "error",
								message: error.response.data.message,
								duration: 3,
							});
						}
						navigate.push("/register");
					});
			}
			if (mode == "l") {
				localStorage.setItem("token", token);
				navigate.push("/dashboard");
			}
		}
	}

	async function fetchData() {
		const options = {
			headers: {
				"x-api-key": API_KEY,
			},
		};
		await axios
			.get(
				"https://seashell-app-k4hn9.ondigitalocean.app/api/v1/location/state",
				options
			)
			.then((response) => {
				setStateData(response.data);
			})
			.catch((error) => {
				console.log("Unsuccessfull", error);
			});
	}

	async function fetchLgas(state) {
		const options = {
			headers: {
				"x-api-key": API_KEY,
			},
		};
		const response = await axios.get(
			`https://seashell-app-k4hn9.ondigitalocean.app/api/v1/location/state/name/${state}/lgas`,
			options
		);
		setLgaData(response.data);
	}

	async function fetchWards(lga) {
		const options = {
			headers: {
				"x-api-key": API_KEY,
			},
		};
		const response = await axios.get(
			`https://seashell-app-k4hn9.ondigitalocean.app/api/v1/location/lga/name/${lga}/wards`,
			options
		);
		setWardsData(response.data);
	}

	async function fetchPollingUnits(ward) {
		const options = {
			headers: {
				"x-api-key": API_KEY,
			},
		};
		const response = await axios.get(
			`https://seashell-app-k4hn9.ondigitalocean.app/api/v1/location/ward/name/${ward}/polling-units`,
			options
		);
		setPollingUnitData(response.data);
	}

	if (pollingUnitData == null) {
		// console.log("pollingUnitData is null or undefined");
	} else {
		newPollingUnitArray = pollingUnitData.map((obj) => {
			return {
				label: obj.name,
				value: obj.name,
			};
		});
	}

	if (wardsData == null) {
		// console.log("wardsData is null or undefined");
	} else {
		newWardArray = wardsData.map((obj) => {
			return {
				label: obj.name,
				value: obj.name,
			};
		});
	}

	if (stateData !== null) {
		stateData.map((state) => {
			// console.log(state)
			stateOptions.push({
				value: state.name,
				label: state.name,
			});
		});
	}

	if (lgaData !== null) {
		lgaData.map((lga) => {
			lgaOptions.push({
				value: lga.name,
				label: lga.name,
			});
		});
	}

	async function pdfToImage(pdfUrl) {
		console.log(pdfUrl);
		const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
		const page = await pdf.getPage(1);
		const viewport = page.getViewport({ scale: 1 });
		const canvas = document.createElement("canvas");
		canvas.width = viewport.width;
		canvas.height = viewport.height;
		const ctx = canvas.getContext("2d");
		const renderContext = {
			canvasContext: ctx,
			viewport: viewport,
		};
		await page.render(renderContext).promise;
		return canvas.toDataURL();
	}

	const dataCode = (val) => {
		const result = val.split(" "); // split the string into an array of characters
		return result[0];
	};

	const getIPAddress = async () => {
		const response = await fetch("https://api.ipify.org?format=json");
		const data = await response.json();
		return data.ip;
	};

	getIPAddress().then((ipAddress) => setIpAddress(ipAddress));

	const handleReset = () => {
		formRef.current.resetFields();
	};

	const handleTranscribe = async (data) => {
		if (token != null && token != undefined && token != "undefined") {
			let stateCode = dataCode(state);
			let lgaCode = dataCode(lga);
			setImageLoading(true);
			await axios
				.get(
					`${BASE_URL}/result-doc/fetch?stateCode=${stateCode}&lgaCode=${lgaCode}`,
					postOptions
				)
				.then((response) => {
					setResultDoc(response.data);
					setImageLoading(false);
				})
				.catch((error) => {
					setImageLoading(false);
					CustomNotification({
						title: "",
						type: "success",
						message: error?.response?.data?.message,
						duration: 3,
					});
					console.log("Unsuccessfull", error);
				});
		}
	};

	const submitTranscribe = async (data) => {
		settranscribeFormIsLoading(true);
		setImageLoading(true);
		await axios
			.post(
				"https://coral-app-n4pvh.ondigitalocean.app/api/v1/result-transcibe/transcibe",
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": " application/json",
					},
				}
			)
			.then((response) => {
				setResultDoc(response.data);
				settranscribeFormIsLoading(false);
				setImageLoading(false);
				CustomNotification({
					title: "Success",
					type: "success",
					message: "Form submitted successfully",
					duration: 1,
				});
				handleReset();
			})
			.catch((error) => {
				setImageLoading(false);
				CustomNotification({
					type: "success",
					message: error?.response?.data?.message,
					duration: 1,
				});
				settranscribeFormIsLoading(false);
			});
	};

	const unclearImage = async (data) => {
		setImageLoading(true);

		await axios
			.post(
				"https://coral-app-n4pvh.ondigitalocean.app/api/v1/result-transcibe/unclear-image",
				data,
				postOptions
			)
			.then((response) => {
				setImageLoading(false);
				CustomNotification({
					title: "",
					type: "success",
					message: "Fetching new image",
					duration: 1,
				});
				setResultDoc(response.data);

				handleReset();
			})
			.catch((error) => {
				setImageLoading(false);
				CustomNotification({
					title: "",
					type: "error",
					message: error?.response?.data?.message,
					duration: 1,
				});
			});
	};

	const invalidForm = async (data) => {
		setImageLoading(true);
		await axios
			.post(
				"https://coral-app-n4pvh.ondigitalocean.app/api/v1/result-transcibe/invalid-form",
				data,
				postOptions
			)
			.then((response) => {
				CustomNotification({
					title: "",
					type: "success",
					message: "Fetching new image",
					duration: 1,
				});
				setResultDoc(response.data);

				setImageLoading(false);
				console.log(response.data);
				handleReset();
			})
			.catch((error) => {
				console.log("Unsuccessfull", error);
				setImageLoading(false);
				CustomNotification({
					title: "",
					type: "error",
					message: error?.response?.data?.message,
					duration: 1,
				});
			});
	};

	function filterArrayAndGetOneValue(string, array) {
		const filteredItems = array.filter((item) => {
			const firstWord = item.value.split(" ")[0];
			return firstWord.toLowerCase() === string.toLowerCase();
		});
		return filteredItems;
	}

	function getStateInitialValue(code) {
		if (stateOptions != null) {
			let state = filterArrayAndGetOneValue(code, stateOptions);
			if (state.length > 0) {
				setStateValue(state[0].label);

				let formattedValue = state[0].label.slice(5).replace(/\//g, "%2F");
				fetchLgas(formattedValue);
			}
		}
	}

	function getLgaInitialValue(code) {
		if (lgaOptions.length > 0) {
			let lga = filterArrayAndGetOneValue(code, lgaOptions);
			if (lga.length > 0) {
				setLgaValue(lga[0].label);

				let formattedValue = lga[0].label.slice(5).replace(/\//g, "%2F");
				fetchWards(formattedValue);
			}
		}
	}

	function getWardInitialValue(code) {
		if (newWardArray.length > 0) {
			let ward = filterArrayAndGetOneValue(code, newWardArray);
			if (ward.length > 0) {
				setWardValue(ward[0].label);

				let formattedValue = ward[0].label.slice(5).replace(/\//g, "%2F");
				fetchPollingUnits(formattedValue);
			}
		}
	}

	function getPollingUnitInitialValue(code) {
		if (newPollingUnitArray.length > 0) {
			let pollingUnit = filterArrayAndGetOneValue(code, newPollingUnitArray);

			if (pollingUnit.length > 0) {
				setPollingUnitValue(pollingUnit[0].label);
			}
		}
	}

	useEffect(() => {
		if (resultDoc != null) {
			const { statecode, lgacode, wardcode } = resultDoc;
			getStateInitialValue(statecode);
		}
	}, [resultDoc]);

	useEffect(() => {
		if (resultDoc != null) {
			const { statecode, lgacode, wardcode } = resultDoc;
			getLgaInitialValue(lgacode);
		}
	}, [stateValue, lgaData]);

	useEffect(() => {
		if (resultDoc != null) {
			const { wardcode } = resultDoc;
			getWardInitialValue(wardcode);
		}
	}, [lgaValue, wardsData]);

	useEffect(() => {
		if (resultDoc != null) {
			const { pollingunitcode } = resultDoc;
			getPollingUnitInitialValue(pollingunitcode);
		}
	}, [wardValue, newPollingUnitArray]);

	const value = {
		fetchLgas,
		fetchWards,
		fetchPollingUnits,
		setState,
		setLga,
		state,
		lga,
		newWardArray,
		newPollingUnitArray,
		stateOptions,
		lgaOptions,
		imageLoading,
		dataCode,
		resultDoc,
		updateUser,
		ipAddress,
		submitTranscribe,
		verifyAccount,
		formRef,
		unclearImage,
		invalidForm,
		transcribeFormIsLoading,
		lgaValue,
		wardValue,
		pollingUnitValue,
		pdfToImage,
		stateValue,
		fetchData,
		filterArrayAndGetOneValue,
		user,
		handleTranscribe,
	};

	return (
		<CollationPortalContext.Provider value={value}>
			{children}
		</CollationPortalContext.Provider>
	);
};

export default CollationProvider;
