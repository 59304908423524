import { notification } from 'antd';
import 'antd/dist/reset.css';


export const CustomNotification = ({ title, type, message, duration }) => {
    const validTypes = ['success', 'error'];

    const args = {
        message: title,
        description: message,
        // duration: duration !== undefined ? duration : null,
        duration: 3,
        icon: null,
        className:
            type === 'success'
                ? 'custom-notification custom-success-notification'
                : 'custom-notification custom-error-notification',
    };
    const serializedType = validTypes.includes(type) ? type : 'open';
    return notification[serializedType](args);
};

