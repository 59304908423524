
import styled from 'styled-components';
import React, {  useEffect, useContext } from "react"
import { CollationPortalContext } from '../context';




export const AccountVerification = () => {
    const {verifyAccount} = useContext(CollationPortalContext)

    useEffect(() => {
        verifyAccount()
    }, [])

    return (

      <>
        <h1>Verify...</h1>
      </>
    );
}

