import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import { SignUp } from "./components/SignUp";
import { SignIn } from "./components/SignIn";

import CollationProvider from "./components/context/index"
import AuthProvider from "./components/context/auth"
import { Verification } from "./components/VerificationScreen";
import { LoginVerification } from "./components/VerificationScreen/login";
import { AccountVerification } from "./components/HomePage/verify";
import ProtectedRoute from "./components/protectedRoute";

import { Redirect, Route } from "react-router-dom";


function App() {
  return (
    <Router>
      <Switch>

        <Route path="/" exact>
          <AuthProvider >
            <SignUp />
          </AuthProvider>
        </Route>

        <ProtectedRoute path="/dashboard" component={HomePage} />

        <Route path="/verify">
          <CollationProvider>
            <AccountVerification />
          </CollationProvider>

        </Route>

        <Route path="/login">
          <AuthProvider>
            <SignIn />
          </AuthProvider>
        </Route>

        <Route path="/registration-success">
          <AuthProvider>
            <Verification />
          </AuthProvider>
        </Route>

        <Route path="/login-success">
          <AuthProvider>
            <LoginVerification />
          </AuthProvider>
        </Route>


      </Switch>
    </Router>
  );
}

export default App;
const frame61Data = {
  children: "Ward",
};

const frame62Data = {
  children: "Polling Unit",
};

const desktop1Data = {
  frame1: "/img/frame-1.png",
  theNigerianCitizen: "The Nigerian Citizens Voters Collation Portal is an essential tool for ensuring transparency and accountability in the electoral process.",
  nigerianCitizensVo: "Nigerian Citizens Voter’s Collation Portal",
  title: "Voters Result",
  votersResult: "Voters Result",
  state: "State",
  arrowDown1: "/img/arrow-down@2x.png",
  localGovernment: "Local Government",
  arrowDown2: "/img/arrow-down@2x.png",
  uploadResult: "Upload Result",
  uploadIcon: "/img/upload-icon@2x.png",
  spanText1: "Drag & drop files or",
  spanText2: " ",
  spanText3: "Browse",
  supportedFormates: "Supported formates: JPEG, PNG,  PDF,Word, CSV",
  submit: "Submit",
  pleaseUploadAClea: "Please upload a clear and concise result of your Unit.",
  overlapGroup1: "/img/14206-1.png",
  copyright: "/img/copyright@2x.png",
  address: "2023 All Right Reserved",
  frame61Props: frame61Data,
  frame62Props: frame62Data,
};

const frame151Data = {
  spanText: "State",
};

const frame152Data = {
  spanText: "Local Government",
  className: "frame-1-4",
};

const frame171Data = {
  children: "Ward",
};

const frame172Data = {
  children: "Polling Unit",
  className: "frame-1-5",
};

const iPhone13Mini1Data = {
  spanText1: "Voters Result",
  frame2: "/img/frame-2-1@2x.png",
  frame1: "/img/frame-1-2@2x.png",
  spanText2: "Nigerian Citizens Voters Collation Portal",
  spanText3: "The Nigerian Citizens Voters Collation Portal is an essential tool for ensuring transparency and accountability in the electoral process.",
  spanText4: "Upload Result",
  uploadIcon: "/img/upload-icon-1@2x.png",
  spanText5: "Drag & drop files or",
  spanText6: " ",
  spanText7: "Browse",
  spanText8: "Supported formates: JPEG, PNG,  PDF,Word, CSV",
  spanText9: "Submit",
  spanText10: "Voters Result",
  spanText11: "Voters Result",
  spanText12: "Please upload a clear and concise result of your Unit.",
  overlapGroup1: "/img/14206-1-2@2x.png",
  copyright: "/img/copyright-2@2x.png",
  spanText13: "2023 All Right Reserved",
  frame151Props: frame151Data,
  frame152Props: frame152Data,
  frame171Props: frame171Data,
  frame172Props: frame172Data,
};

