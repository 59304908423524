
import styled from 'styled-components';
import React, { useState } from "react"

import { Form, Input, Button, Row, Col, Select } from 'antd';
import Mail from "../../static/img/mailIcon.png"




export const LoginVerification = () => {




    return (

        <Container>

            <div className="content">
                <div className="login">
                    <IconContainer src={Mail} />
                </div>
                <h3>Login Link Sent </h3>
                <p>An email has been sent to you with the details for you to log in.

                </p>
                <p>
                    Click the "Login" button in your  email to log into CollationPortal
                </p>

                <p>Thank you for taking part in ensuring  Our Elections are Free and Fair.
                </p>


            </div>
        </Container>
    );
}


const Container = styled.div`
margin-top: 100px;
display: flex;
flex-direction: column;
height: 600px;
align-items: center;
justify-content: center;
text-align: center;



.content{
    width: 400px;

    .submit-btn{
        width: 100%;
        border-radius: 0px;
        cursor: pointer;
        padding: 12px 28px;
        color: rgb(255, 255, 255);
        border: 1px solid   #008000;;
        font-weight: bold;
        border-radius: 4px;
        background-color:  #008000;
        border: 1px solid #008000;
        margin: 16px 0px 0px;
        height: 50px;

        :disabled{
            border: 1px solid rgb(221, 221, 221);
            cursor: not-allowed;
        }
            
        :hover{
            background: transparent;
            background: rgba(255, 255, 255, 0.757);
            color: #5DADE4;
        }
      }
    @media (max-width: 768px) {
    padding: 20px;
    width: 300px;
}


}
@media (max-width: 768px) {
    padding: 20px;
}

h3{
    font-size: 36px;
    font-weight: 700;
    margin-top: 30.43px;
    @media (max-width: 768px) {
    font-size: 20px;
}
}

p{
    color: #2222229b;
    font-size: 18px;
    font-weight: 400;
    margin-top: 30px;
    text-align: left;
    @media (max-width: 768px) {
        font-size: 14px;
        padding: 0px 20px;
        text-align: center;
}
}
`;


const IconContainer = styled.img`   
 
`;