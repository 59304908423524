import React, { createContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { CustomNotification } from "../Notifications";

// Create a new context
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const token = localStorage.getItem("token");

	const [loading, setLoading] = useState(false);
	const [ipAddress, setIpAddress] = useState("");
	const [successMsg, setSuccessMsg] = useState("");

	const navigate = useHistory();

	const registerUser = async (data) => {
		setLoading(true);

		await axios
			.post(
				"https://coral-app-n4pvh.ondigitalocean.app/api/v1/auth/sign-up",
				data
			)
			.then((response) => {
				const { user, message, token } = response.data;
				if (user.id !== null) {
					setSuccessMsg(message);
					localStorage.setItem("token", token);
					CustomNotification({
						title: "",
						type: "success",
						message: message,
						duration: 3,
					});
					navigate.push("/dashboard");
				}
				setLoading(false);
			})
			.catch((error) => {
				CustomNotification({
					title: "Error",
					type: "error",
					message: error.response.data.message,
					duration: 3,
				});
				console.log(error);
				setLoading(false);
			});
	};

	const loginUser = async (data) => {
		setLoading(true);

		await axios
			.post(
				"https://coral-app-n4pvh.ondigitalocean.app/api/v1/auth/login",
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				console.log(response.data, "login response");
				if (response.data.success) {
					// CustomNotification({
					//     title: "Success",
					//     type: "success",
					//     message: "An Email has been sent to the provided email address below",
					//     duration: 5,
					// });
					navigate.push("/login-success");
				}
				setLoading(false);
			})
			.catch((error) => {
				if (error.response.data) {
					CustomNotification({
						title: "Error",
						type: "error",
						message: error.response.data.message,
						duration: 3,
					});
				}
				setLoading(false);
			});
	};

	const getIPAddress = async () => {
		const response = await fetch("https://api.ipify.org?format=json");
		const data = await response.json();
		return data.ip;
	};

	getIPAddress().then((ipAddress) => setIpAddress(ipAddress));

	const value = {
		registerUser,
		loginUser,
		ipAddress,
		successMsg,
		loading,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
