
import styled from 'styled-components';
import React, { useState, useContext } from "react"
import { AuthContext } from '../context/auth';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Select, Spin } from 'antd';

export const SignUp = () => {

    const { loading, registerUser, ipAddress } = useContext(AuthContext)

    const options = [
        { label: 'Nigeria', value: 'Nigeria' },
        { label: 'United Kingdom', value: ' United Kingdom' },
        { label: 'United States', value: 'United States' },
        { label: 'Canada', value: 'Canada' },
        { label: 'UAE', value: 'UAE' },
        { label: 'Australia', value: 'Australia' },
        { label: 'South Africa', value: 'South Africa' },
        { label: 'Germany', value: 'Germany' },
    ]
    const [country, setCountry] = useState("");

    const handleSearch = (value) => {
        const filtered = options.filter((option) =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
    };


    const handleOnChange = (value) => {
        setCountry(value)

        console.log(value)

    };



    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        const data = { ...values, ip: ipAddress }
        registerUser(data)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onSearch = (errorInfo) => {
        console.log('Form submission failed:', errorInfo);
    };


    console.log(country)


    return (

        <Container>



            <div class="left-pane">
                <div className="form-container">
                    <Header>
                        <h2>Create An Account</h2>
                        <p>Please enter your details</p>
                    </Header>
                    <Form
                        requiredMark={false}
                        layout='vertical'
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="First Name"
                            name="firstName"

                            requiredMark={false}
                            xs={{ span: 24 }} md={{ span: 12 }}
                            rules={[{ required: true, message: 'Please provide us with your first name!' }]}
                        >
                            <Input placeholder="Please enter  your first name" />
                        </Form.Item>


                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            requiredMark={false}
                            xs={{ span: 24 }} md={{ span: 12 }}
                            rules={[{ required: true, message: 'Please provide us with your last name!' }]}
                        >
                            <Input placeholder='Please enter  your last name  ' />
                        </Form.Item>


                        <Form.Item
                            label="Email"
                            name="email"
                            requiredMark={false}
                            xs={{ span: 24 }} md={{ span: 12 }}
                            rules={[{ required: true, message: 'Please provide an email address!' }]}
                        >
                            <Input placeholder="Please enter  your email address" />
                        </Form.Item>

                        <Form.Item
                            label="Phone Number"
                            name="phone"
                            requiredMark={false}
                            rules={[{ required: true, message: 'Please provide a phone number!' }]}
                        >
                            <Input placeholder='Please enter  your phone number' />
                        </Form.Item>


                        <Form.Item
                            label="Country"
                            name="location"

                            requiredMark={false}
                            rules={[{ required: true, message: 'Please provide a country!' }]}
                        >
                            {/* <Select
                            showSearch
                            onSearch={handleSearch}
                            onChange={ () => handleOnChange()}
                            value={country}
                            placeholder="Select a country"
                            options={options}
                        >
                            
                        </Select> */}


                            <Select
                                
                                showSearch
                                placeholder="Select country"
                                optionFilterProp=""
                                onChange={ handleOnChange}
                                defaultValue={country}
                                onSearch={onSearch}
                                allowClear={true}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={options}

                            />

                        </Form.Item>



                        <Form.Item>
                            <Button className='submit-btn' type="primary" htmlType="submit">
                              {loading ? <Spin /> : " Create An Account"}
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="link-cont">
                        <p>Already have an account? <span> <Link to="/login">Sign In</Link> </span></p>
                    </div>
                </div>

            </div>
            <div class="right-pane">

            </div>


        </Container>
    );
}



const Container = styled.div`

    display: flex;
    flex-direction: row;
    height: 100vh;

    .left-pane {
        flex: 1; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .right-pane {
        flex: 1; 
        background-image: url("../../img/banner.jpg");

        background-size: cover;

        @media (max-width: 786px) {

            display: none;

            }
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        height: 50px !important;
        align-items: center;
    }
      .submit-btn{
        width: 100%;
        border-radius: 0px;
        cursor: pointer;
        padding: 12px 28px;
        color: rgb(255, 255, 255);
        border: 1px solid   #008000;;
        font-weight: bold;
        border-radius: 4px;
        background-color:  #008000;
        border: 1px solid #008000;
        margin: 16px 0px 0px;
        height: 50px;

            :disabled{
                border: 1px solid rgb(221, 221, 221);
                cursor: not-allowed;
            }
              
            :hover{
                background: transparent;
                background: rgba(255, 255, 255, 0.757);
                color: #008000;
            }
      }

    justify-content: center;


    .link-cont{
        display: flex;
        align-items: center;
        justify-content: space-evenly; 
        p{
            padding-top: 20px;
            color: #22222289;

            span a{

                color:  #008000;
            }
        }
        
    }

    .form-container{
        width: 500px;
        padding: 0px 30px;

        @media (max-width: 768px)  {
            width: 100%;
            padding: 0px 20px;
        }


    }


 .ant-input {
   width: 100% !important;
  border: 1px solid #ccc !important;
  padding: 12px 12px !important;
  font-size: 16px;
}




.ant-form{
    margin-top: 20px;
}

.ant-label{
    font-weight: 700;
}

.ant-input:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.ant-input::placeholder {
  color: #999;
  font-size: 14px;
}
 
`;


const Header = styled.div`

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    h2{
        font-size: 30px;
        font-weight: 800;
        margin-top: 20px;
      
        
    }
    p{
        font-size: 16px;
        color: #22222289;
        text-align: center;
    }

    @media (max-width: 786px) {
        h3{
            font-size: 20px;
            
        }
        p{
            font-size: 14px;
        }
        

}
 
`;